import React from 'react'
import { useState } from 'react';
import Validate from './validation.js';
import FieldText from './field_text.js';
import FieldEmail from './field_email.js';
import FieldTextArea from './field_textarea.js';
import FieldErrorMessage from './field_error_message';
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY = '6LeypZMdAAAAAIz6lhoiHdB8f3VTYxVSTD3A0I2r';

const FormEnquiry = () => 
{
  const recaptchaRef = React.createRef();
  const [formValues, setFormValues] = useState({
    formErrors: '',
    formIsValid: false,
    formControls: {
      name: {
        type: 'text',
        value: '',
        placeholder: '',
        valid: false,
        touched: false,
        validationRules: {
            isRequired:  true,
        },
        errorMessages: ''
      },
      email: {
        type: 'email',
        value: '',
        placeholder: '',
        valid: false,
        touched: false,
        validationRules: {
            isRequired: true,
            isEmail: true
        },
        errorMessages: ''
      },
      phone: {
        type: 'text',
        value: '',
        placeholder: '',
        valid: false,
        touched: false,
        validationRules: {
            isRequired: true
        },
        errorMessages: ''
      },
      enquiry: {
        type: 'text',
        value: '',
        placeholder: '',
        valid: false,
        touched: false,
        validationRules: {
            isRequired: true
        },
        errorMessages: ''
      },
    }
  });

  const changeHandler = e => {
    const name = e.target.name;
    const value = e.target.value;

    const updatedControls = {
      ...formValues.formControls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };
    updatedFormElement.value = value;
    updatedFormElement.touched = true;

    let validateFormElement = [];
    validateFormElement = Validate(value, updatedFormElement.validationRules);

    updatedFormElement.valid = validateFormElement.isValid;
    updatedFormElement.errorMessages = validateFormElement.errorMessages;

    updatedControls[name] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }

    setFormValues({
      ...formValues,
      formControls: updatedControls,
      formIsValid: formIsValid,
    });
  }

  const submitHandler = async e => 
  {
    e.preventDefault();
    e.persist();
    
    const token = await recaptchaRef.current.executeAsync();
    const form = e.target;
    const formData = {};

    for (let formElementId in formValues.formControls) {
      formData[formElementId] = formValues.formControls[formElementId].value
    }
    
    const encode = (formData) => {
      return Object.keys(formData)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(formData[key]))
        .join("&");
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ 
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": token,
        ...formData
      })
    })
    .then(() => window.location.href = '/contact/thanks/')
    .catch(error => alert(error));
  }

  return (
    <form name="contact" method="post" action="/contact/thanks/" data-netlify="true" data-netlify-recaptcha="true" onSubmit={submitHandler} className="font-ubuntu w-full">
      <input type="hidden" name="form-name" value="contact" />
      <label htmlFor="name" className="font-ubuntu prose md:prose-xl pb-1 block">Name:<span>*</span></label>
      <FieldText
        id="name"
        name="name"
        type={formValues.formControls.name.type}
        placeholder={formValues.formControls.name.placeholder}
        value={formValues.formControls.name.value}
        onChange={changeHandler}
        className={formValues.formControls.name.touched && !formValues.formControls.name.valid ? 'font-ubuntu text-lg w-1/2 px-2 py-1 border-2 border-red mb-1 focus:outline-none' : 'font-ubuntu text-lg w-1/2 px-2 py-1 border-2 border-grey mb-4 focus:outline-none'}
      />
      <FieldErrorMessage
        className={formValues.formControls.name.touched && !formValues.formControls.name.valid ? 'font-ubuntu block prose md:prose-lg text-red mb-4' : 'hidden'}
        errorMessages={formValues.formControls.name.errorMessages}
      />
      <label htmlFor="email" className="font-ubuntu prose md:prose-xl pb-1 block">Email:<span>*</span></label>
      <FieldEmail
        id="email"
        name="email"
        type={formValues.formControls.email.type}
        placeholder={formValues.formControls.email.placeholder}
        value={formValues.formControls.email.value}
        onChange={changeHandler}
        className={formValues.formControls.email.touched && !formValues.formControls.email.valid ? 'font-ubuntu text-lg w-full px-2 py-1 border-2 border-red mb-1 focus:outline-none' : 'font-ubuntu text-lg w-full px-2 py-1 border-2 border-grey mb-4 focus:outline-none'}
      />
      <FieldErrorMessage
        className={formValues.formControls.email.touched && !formValues.formControls.email.valid ? 'font-ubuntu block prose md:prose-lg text-red mb-4' : 'hidden'}
        errorMessages={formValues.formControls.email.errorMessages}
      />
      <label htmlFor="phone" className="font-ubuntu prose md:prose-xl pb-1 block">Phone:<span>*</span></label>
      <FieldText
        id="phone"
        name="phone"
        type={formValues.formControls.phone.type}
        placeholder={formValues.formControls.phone.placeholder}
        value={formValues.formControls.phone.value}
        onChange={changeHandler}
        className={formValues.formControls.phone.touched && !formValues.formControls.phone.valid ? 'font-ubuntu text-lg w-1/2 px-2 py-1 border-2 border-red mb-1 focus:outline-none' : 'font-ubuntu text-lg w-1/2 px-2 py-1 border-2 border-grey mb-4 focus:outline-none'}
      />
      <FieldErrorMessage
        className={formValues.formControls.phone.touched && !formValues.formControls.phone.valid ? 'font-ubuntu prose md:prose-lg text-red mb-4' : 'hidden'}
        errorMessages={formValues.formControls.phone.errorMessages}
      />
      <label htmlFor="enquiry" className="font-ubuntu prose md:prose-xl pb-1 block">Enquiry:<span>*</span></label>
      <FieldTextArea
        id="enquiry"
        name="enquiry"
        type={formValues.formControls.enquiry.type}
        placeholder={formValues.formControls.enquiry.placeholder}
        value={formValues.formControls.enquiry.value}
        onChange={changeHandler}
        className={formValues.formControls.enquiry.touched && !formValues.formControls.enquiry.valid ? 'font-ubuntu block text-lg w-full px-2 py-1 border-2 border-red h-40 focus:outline-none' : 'block font-ubuntu text-lg w-full px-2 py-1 border-2 border-grey mb-4 h-40 focus:outline-none'}
      />
      <FieldErrorMessage
        className={formValues.formControls.enquiry.touched && !formValues.formControls.enquiry.valid ? 'font-ubuntu block prose md:prose-lg text-red mb-4' : 'hidden'}
        errorMessages={formValues.formControls.enquiry.errorMessages}
      />
      <FieldErrorMessage
        className={formValues.formErrors ? 'block field__errors' : 'hidden field__errors'}
        errorMessages={formValues.formErrors}
      />
      <ReCAPTCHA
        size="invisible"
        ref={recaptchaRef}
        sitekey={SITE_KEY}
      />
      <button type="submit"
        className="font-ubuntu mt-1 text-2xl py-2 px-4 border-2 border-red inline-block text-red focus:outline-none disabled:cursor-not-allowed"
        disabled={!formValues.formIsValid}
      >
        Submit <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
      </button>
    </form>
  )
}

export default FormEnquiry