import * as React from "react"
import Seo from "../../components/seo"
import { RichText } from 'prismic-reactjs'
import { graphql } from "gatsby"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Copyright from "../../components/copyright"
import Form from "../../components/form"

const ContactPage = ({data}) => {
  const entry = data.prismicContactPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname="/contact/" />
      <Header />
      <div className="w-full py-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex:none sm:flex justify-between gap-24">
            <div className="w-full sm:w-8/12">
              <div className="font-ubuntu font-bold text-red text-4xl sm:text-5xl leading-tight mb-6">
                <h1>{entry.data.heading.text}</h1>
              </div>
              <div className="font-ubuntu prose prose-xl leading-normal mb-6">
                {RichText.render(entry.data.text.richText)}
              </div>
              <Form />
            </div>
            <div className="w-full sm:w-4/12 mt-12 sm:mt-0">
              <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.2917602715265!2d174.62809361529227!3d-36.883367979931485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d41d5c80edb3f%3A0x74817ae418a8b845!2sDanco%20NZ%20Ltd!5e0!3m2!1sen!2snz!4v1646943083403!5m2!1sen!2snz" width="100%" height="100%" className="border-0" allowFullScreen="" loading="lazy"></iframe>   
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Copyright />
    </>
  )
}

export const query = graphql`
query {
  prismicContactPage {
    data {
      text {
        richText
      }
      page_title
      meta_description
      heading {
        text
      }
    }
  }
}
`

export default ContactPage
